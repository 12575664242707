<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Servis Kayıtları</b-card-title>
    </b-card-header>
    <b-alert
        v-if="!dataList.length > 0"
        variant="warning"
        show
        class="m-2"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" /> Servis kaydınız bulunmamaktadır.
      </div>
    </b-alert>
    <template v-else>
      <b-table
        class="mt-1"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(company)="data">
          <b-link
            class="text-body"
            :to="$route.path + '/view/' + data.item.id"
          >
            {{ data.item.company }}
          </b-link>
        </template>
        <template #cell(service_status)="data">
          <div
            v-if="data.item.brand"
            class="text-success font-small-2 font-weight-bold"
          >
            {{ data.item.brand }}
          </div>
          {{ data.item.service_status }}
          <div class="font-small-2 text-primary">
            {{ data.item.username? data.item.username : data.item.dealer_user }}
            <div
              v-if="data.item.dealer_user"
              class="font-small-2 text-muted"
            >
              (Bayi Kullanıcısı)
            </div>
            <div>
              <span class="text-secondary">
                {{moment(data.item.created).format('DD.MM.YYYY')}}
              </span>
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            size="sm"
            variant="primary"
            :to="$route.path + '/view/' + data.item.id"
          >
            Görüntüle
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
import {
  BCardTitle, BCardHeader, BCard, BTable, BPagination, BCardFooter, BLink, BButton, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'services'
export default {
  name: 'Index',
  components: {
    BAlert,
    BCardTitle,
    BCardHeader,
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BLink,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'service_type',
          label: 'SERVİS İŞLEMİ',
        },
        {
          key: 'service_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.created AS created`,
          'customers.company AS company',
          'services.sdate AS sdate',
          'services.edate AS edate',
          'service_types.title AS service_type',
          'service_statuses.title AS service_status',
          'brands.title AS brand',
          'users.name AS username',
          'dealer_users.name AS dealer_user',
        ],
        order_by: ['services.id', 'DESC'],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['services/dataList']
    },
    dataCounts() {
      return this.$store.getters['services/dataCounts']
    },
    saveData() {
      return this.$store.getters['services/dataSaveStatus']
    },
    search() {
      return this.$store.getters['services/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
        }
      }
      if (this.search.id_brands) {
        this.dataQuery.where = {
          'services.id_brands': this.search.id_brands,
        }
      } else {
        this.dataQuery.where = {}
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('services/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('services/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
